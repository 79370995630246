import React from 'react';

function WhatIDidAndContact(props) {

  // const images = require.context('./../images', true);

  return (
    <div className="section" id="s5">
          <div className="container grid-lg">
              <div className="columns">
                <div className="column col-12"><h2>{props.children}</h2></div>
              </div>
          </div>
          <div className="container grid-lg">
              <div className="columns">
                <div className="column col-md-12 col-4">
                  <p>
                  <a target={props.blank} href={props.linkMyJob1}>{props.linkMyJob1}</a>
                  <a target={props.blank} href={props.linkMyJob2}>{props.linkMyJob2}</a>
                  <a target={props.blank} href={props.linkMyJob3}>{props.linkMyJob3}</a>
                  <a target={props.blank} href={props.linkLogCenter}>{props.linkLogCenter}</a>
                  </p>
                </div>
                <div className="column col-md-12 col-8">
                  <div className="wrap-box">
                      <div className="box">
                        <div className="content location">
                                Hochiminh City, Vietnam
                            </div>
                            <div className="content email">
                                <a target={props.blank} href={props.linkEmail}>phangia2712@gmail.com</a>
                            </div>
                      </div>
                      <div className="box">
                        <div className="content phone">
                          <a target={props.blank} href={props.linkPhone}>(+84) 908.066.024</a>
                            </div>
                            <div className="content linked">
                            <a target={props.blank} href={props.linkLinked}>linkedin.com/in/phangiatuananh</a>
                            </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  );
}

export default WhatIDidAndContact;
