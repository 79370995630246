import React from 'react';

function EducationAndHobby(props) {

  const images = require.context('./../images', true);

  return (
    <div className="section" id="s4">
          <div className="container grid-lg">
              <div className="columns">
                <div className="column col-md-12 col-7">
                  <h2>{props.children}</h2>
                  <h4>Hoa Sen University</h4>
                  <p>I majored in Information Technology</p>
                  <p>I love football, comic books. I'm also quite interested in PHP, so I made a small project about it to show my Experience. <br />
                  <a target={props.blank} href={props.linkMySite}>Visit my Site > ></a>
                  </p>
                </div>
                <div className="column col-md-12 col-5">
                  <div className="img-box">
                    <img src={images('./student.svg')} alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
  );
}

export default EducationAndHobby;
