import React from 'react';

function Hello(props) {

  const images = require.context('./../images', true);

  return (
    <div className="section" id="s1">
            <div className="container grid-lg">
              <div className="columns">
                <div className="column col-md-12 col-3">
                    <div className="parallax">
                      <div className="parallax-top-left" tabIndex="1"></div>
                      <div className="parallax-top-right" tabIndex="2"></div>
                      <div className="parallax-bottom-left" tabIndex="3"></div>
                      <div className="parallax-bottom-right" tabIndex="4"></div>
                      <div className="parallax-content">
                          <div className="parallax-back">
                          <img src={images('./phangia.png')} className="img-responsive rounded" alt="Phan Gia Tuan Anh" />
                          </div>
                      </div>
                  </div>
                </div>
                <div className="column col-md-12 col-9">
                    <div className="box">
                    <div class="typewriter">
                      <h1 className="typing" style={{paddingTop: '20px'}}>{props.children}</h1>
                    </div>
                  <h4><em>"I’m wedded to HTML/CSS over 5 years &amp; Jquery - JS over 1 year. So I think Front - End Developer
seems to be my destiny :))”</em></h4>
</div>
                </div>
              </div>
            </div>
          </div>
  );
}

export default Hello;
