import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import 'fullpage.js/vendors/scrolloverflow';
import 'spectre.css';
import './App.scss';
import './experimentals.scss';

import Hello from './components/Hello';
import Experience from './components/Experience';
import Skills from './components/Skills';
import EducationAndHobby from './components/EducationAndHobby';
import WhatIDidAndContact from './components/WhatIDidAndContact';

/* class MySection extends React.Component {
  render() {
    return (
      <div className="section">
        <h3>{this.props.content}</h3>
      </div>
    );
  }
} */

/* function MySection(props){
  return (
    <div className="section">
      <h3>{props.content}</h3>
    </div>
  );
} */

/* bat emmet viet html trong react
"emmet.includeLanguages": {
        "javascript": "javascriptreact"
      },
      "emmet.triggerExpansionOnTab": true
*/

function App() {
  const blank = "_blank";
  const linkMySite = 'https://code.phangiatuananh.tech/';
  const linkMyJob1 ='https://www.muabannhadat.vn/';
  const linkMyJob2 ='https://nhadatphodong.com/';
  const linkMyJob3 ='https://login.innerbeans.info/';
  const linkLinked = 'https://www.linkedin.com/in/phangiatuananh';
  const linkEmail = 'mailto:phangia2712@gmail.com';
  const linkPhone = 'tel:0908066024';
  const linkLogCenter = 'http://www.logcenter.net/'

//   const images = require.context('./images', true);
  const anchors = ["firstPage", "secondPage", "thirdPage", "fourthPage", "fifthPage"];

  return (
    <ReactFullpage
    licenseKey={'your key'}
    anchors={anchors}
    navigation
    navigationTooltips={['Hello', 'Experience', 'Skills', 'Education &amp; Hobby', 'What I did &amp; Contact']}
    loopTop
    loopBottom
    scrollOverflow
    onLeave={(origin, destination, direction) => {
      console.log("onLeave event", { origin, destination, direction });
    }}
    render={({ state, fullpageApi }) => {
      console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

      return (
        <div>
          <Hello>Hello, I'm Allen.</Hello>

          <Experience blank={blank} linkMyJob3={linkMyJob3} linkLogCenter={linkLogCenter}>Experience</Experience>

          <Skills>Skills</Skills>

          <EducationAndHobby /* title={["Education", <br />,  " & Hobby"]} */ blank={blank} linkMySite={linkMySite} >Education<br />&amp; Hobby</EducationAndHobby>

          <WhatIDidAndContact blank={blank} linkMyJob1={linkMyJob1} linkMyJob2={linkMyJob2} linkMyJob3={linkMyJob3} linkLogCenter={linkLogCenter} linkEmail={linkEmail} linkPhone={linkPhone} linkLinked={linkLinked}>What I did &amp; Contact</WhatIDidAndContact>
        </div>
      );
    }}
  />
  );
}

export default App;
