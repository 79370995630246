import React from 'react';

function Experience(props) {

  // const images = require.context('./../images', true);

  return (
    <div className="section" id="s2">
            <div className="container grid-lg">
              <div className="columns">
                <div className="column col-12">
                  <h2>{props.children}</h2>
                  <h4>Front - End Developer</h4>
                  <div className="wrap-box">
                    <div className="box">
                      <div className="content">
                          InnerBus
                          <span>September 2017 – Present</span></div>
                      </div>
                      <div className="box">
                      <div className="content">
                          Ringier AG (MBND)
                          <span>April 2012 – August 2017</span></div>
                      </div>
                      <div className="box">
                        <div className="content">
                            NVG Technology
                            <span>July 2010 – March 2012</span></div>
                      </div>
                  </div>
                  <div className="table-responsive">
                  <table>
                    <tbody>
                    <tr>
                      <td>
                        
                      </td>
                      <td>
                        
                      </td>
                      <td>
                        
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  </div>
                  <p>Work as a part of Product &amp; Technology Team in Ringier &amp; NVG. My duty is to do everything related to
HTML/CSS such as: <br />
Build landing page for promotions, fix bugs layout, make responsive
email (look good on any device, on Gmail App). Cooperate with Sales Team to build
Vip project Microsites, Lead Generation, do html5 banners for client.
</p>
<p>In InnerBus, I'm a part of Web Team. Build web layout in Vue Project (HTML/CSS/JS if needed) from templates of Korean team. Main projects is <a target={props.blank} href={props.linkMyJob3}>Innerbeans</a> &amp; <a target={props.blank} href={props.linkLogCenter}>LogCenter</a></p>
                </div>
              </div>
            </div>
          </div>
  );
}

export default Experience;
