import React from 'react';

function Hello(props) {

  // const images = require.context('./../images', true);

  return (
    <div className="section" id="s3">
          <div className="container grid-lg">
              <div className="columns">
                <div className="column col-md-12 col-5">
                  <h2>{props.children}</h2>
                  <ul>
                    <li>Highly skilled of HTML, CSS</li>
                    <li>Bootstrap &amp; Spectre CSS Framework</li>
                    <li>Scss, Jquery, JS &amp; Responsive Design</li>
                    <li>Photoshop, Illustrator, GWD, VS Code, Git</li>
                    <li>PHP, WordPress &amp; ReactJS</li>
                  </ul>
                </div>
                <div className="column col-md-12 col-7">
                  <h2 className="d-md-none">&nbsp;</h2>
                  <div className="skills">
                    <div><code>&lt;skillset&gt;</code></div>
                    <div className="skillset">
                      <ul className="simple">
                        <li className="html">HTML5</li>
                        <li className="css">CSS3</li>
                        <li className="jquery">jQuery</li>
                        <li className="javascript">JS</li>
                        <li className="bs">Bootstrap</li>
                        <li className="st">Spectre</li>
                        <li className="ai">Illustrator</li>
                        <li className="photoshop">Photoshop</li>
                        <li className="vsc">VS Code</li>
                        <li className="rwd">Responsive Design</li>
                        <li className="php">PHP</li>
                        <li className="rs">ReactJS/VueJS</li>
                        <li className="git">GIT</li>
                        <li className="wp">WordPress</li>
                      </ul>
                    </div>
                  <div><code>&lt;/skillset&gt;</code></div>
            </div>
                </div>
              </div>
            </div>
          </div>
  );
}

export default Hello;
